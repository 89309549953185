import React from 'react'
import './loading.scss'

export default (props) => 
    <div className="loading-anim">
        <div className="loading-anim-wrapper">
            <div className="ring-bg"></div>
            <div className="ring-bg"></div>
            <div className="ring-bg"></div>
            <div className="ring-bg"></div>
        </div>
    </div>

