import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { PostcodeCTA } from "../components/PostcodeCTA/PostcodeCTA";
import LocationMark from '../components/icons/Locations/LocationMark';
import Header from "../components/Header";
import Footer from "../components/Footer";
import BecomeACarer from '../components/BecomeACarer';
import { CarouselComponent, CarouselComponentSlide } from '../components/carousel';
import "../../scss/main.scss";
import GreenCheckMark from "../components/icons/GreenCheckMark";
import Arrow from "../components/icons/LiveInCare/Arrow";
import BrochureRequestPanel from '../components/BrochureRequestPanel'
import TpStars from '../components/Trustpilot/TpStars'
import { MicroCombo } from "../components/Trustpilot/TrustpilotWidgets";

export default function Location({ data }) {
	const {
		airtableLocations,
		site: {
			siteMetadata: {
				appConfig: {
					gtmContainerId
				}
			}
		}
	} = data
    const {data: {Splash_image_sharp, Name: locationName}} = airtableLocations
	const [activity1, activity2, activity3, activity4] = airtableLocations.data.activities.map(res => res.data)
    const splashImage = getImage(Splash_image_sharp)
    const activityImage1 = getImage(activity1.image_sharp)
    const activityImage2 = getImage(activity2.image_sharp)
    const activityImage3 = getImage(activity3.image_sharp)
    const activityImage4 = getImage(activity4.image_sharp)
	const formattedLocationName = locationName.toLowerCase().startsWith("the ") ? locationName.substring(4) : locationName
	const [ctaUrl, setCtaUrl] = useState('/cta')
	useEffect(() => {
		setCtaUrl(`/cta/${document.location.search}`)
	}, [])
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | {formattedLocationName}</title>
				<meta property="og:title" content={`Edyn Care | ${formattedLocationName}`}/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content={`https://www.edyn.care/${airtableLocations.data.slug}`} />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content={`https://www.edyn.care/${airtableLocations.data.slug}`} />
				<meta name="twitter:title" content={`Edyn Care | ${formattedLocationName}`} />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<section>
						<div className="locations-landing">
							<div className="locations-landing-left">
								<div className="landing-content-container">
									<div className="locations-heading">
										<h1>
											Live-in care <br />
											in {airtableLocations.data.Name}
										</h1>
										<p className="primary">
											At Edyn, we provide high-quality modern live-in care.
											We’re with you every step of the way.
										</p>

										<Link to={ctaUrl}>
											<button className="btn-orange">Book a consultation</button>
										</Link>
									</div>
									<div className="landing-left-bottom">
										{/* <div className="trust-pilot-minimal">
											<TpStars score={4.8} className="tp-stars"/>
											<div className="tp-text">
												Rated <b>excellent</b> by Edyn customers on
											</div>
											<StaticImage 
												placeholder="blurred" 
												src="../../static/assets/marketing-site/images/trustpilot-icon-dark.png"
												className="tp-icon"
											/>
										</div> */}
                    <MicroCombo classNames={"trust-pilot-minimal"}/>
										<PostcodeCTA />
									</div>
								</div>
							</div>
							<div className="locations-landing-right">
                <GatsbyImage className="location-img" image={splashImage}/>
								<div className="location-landing-name">
									<LocationMark className="icon-default"/>
									<p>{airtableLocations.data.Splash_Image_location_description}</p>
								</div>
							</div>
						</div>
					</section>
					<hr className="mobile-hide" />

					<section>
						<div className="locations-we-understand">
							<div className="we-understand-left">
								<h2>We understand:</h2>
								<Link to="/approach/" className="button-link">
									<button className="btn-primary green">
										Learn more about our approach
									</button>
								</Link>
							</div>
							<div className="we-understand-right">
								<ul>
									<li className="mobile-only">
										<h2>We understand:</h2>
									</li>
									<li>
										<GreenCheckMark className="icon-default"/>
										<p className="secondary">
											Exceptional care is not an individual job
										</p>
									</li>
									<li>
										<GreenCheckMark className="icon-default"/>

										<p className="secondary">Training is critical</p>
									</li>
									<li>
										<GreenCheckMark className="icon-default"/>
										<p className="secondary">The importance of consistency</p>
									</li>
									<li>
										<GreenCheckMark className="icon-default"/>
										<p className="secondary">
											Technology to make us more human
										</p>
									</li>
									<li className="mobile-only">
										<Link to="/approach/" className="button-link">
											<button className="btn-primary green">
												Learn more about our approach
											</button>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section>
						<div className="locations-exceptional-care">
							<div className="ec-left">
								<h1>Providing exceptional care for the people of {airtableLocations.data.Name}</h1>
								<p className="primary">
									Recognised by industry leaders and academics as one of the
									leading providers of live-in care in {airtableLocations.data.Name} and
									throughout England, Edyn has enabled hundreds of seniors to
									continue living the life they wish in their own home - happily
									and safely.
								</p>
								<p className="primary">
									If you’re concerned that your loved one is struggling to cope
									independently with day-to-day life in the {airtableLocations.data.Name} area,
									then you’re probably considering your care options. Live-in
									care has become an increasingly popular option both in{" "}
									{airtableLocations.data.Name} and throughout the UK. This highly
									personalised, one-to-one type of care is changing how we’re
									looking after our elderly and we’re leading the way.
								</p>
							</div>
							<div className="ec-right">
								<div className="ec-img-container ec-img-container-left">
									<StaticImage
										src="../../static/assets/marketing-site/images/ec-img-1.png"
										alt=""
										placeholder="blurred"
									/>
									<StaticImage
										src="../../static/assets/marketing-site/images/ec-img-2.png"
										alt=""
										placeholder="blurred"
									/>
								</div>
								<div className="ec-img-container ec-img-container-right">
									<StaticImage
										src="../../static/assets/marketing-site/images/ec-img-3.png"
										alt=""
										placeholder="blurred"
									/>
								</div>
							</div>
						</div>
					</section>
						<BecomeACarer
							className="compact locations-page"
							backgroundColor="#ECF3F6"
							link="tel:02039709900"
							title={`Dependable carers across ${airtableLocations.data.Name}`}
							mobileTitle="Dependable carers"
							description="We have compassionate, positive and personable carers available immediately. You have the same care team trained and supported to stay COVID-safe."
							actionButtonChildren={(
								<div className="find-more">
									<div className="contact-detail-cta">To find out more please call us on&nbsp;</div>
									<div>020 3970 9900</div>
								</div>
							)}
						/>
					<section>
						<div className="locations-what-expect">
							<div className="what-expect-left">
								<h2>What can I expect from live-in care in {airtableLocations.data.Name}</h2>
								<p className="secondary middle">
									We offer specialist support for conditions including:
								</p>
								<ul>
									<li>
										<Link to='/condition/dementia/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Dementia</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/parkinsons/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Parkinsons</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/multiple-sclerosis/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>MS care</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/cancer/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Cancer care</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/stroke/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Stroke care</p>
										</Link>
									</li>								</ul>
							</div>
							<div className="what-expect-right">
								<div className="left">
									<h2>
										Live-in care enables your loved one to remain in the comfort
										of their own home, even when they have quite complex care
										needs.
									</h2>
									<p className="primary">
										The beauty is that your loved one does not need to downsize
										and give up possessions that have built up over a lifetime,
										give up a well-tended garden or loose contact with their
										local community. A professional live-in care, trained by our
										care management team, moves into your relatives home to
										provide the support they need around the clock.
									</p>
								</div>
								<div className="right">
									<p className="primary">
										Our highly personalised service is delivered by
										professionals carers who have been chosen for their mutual
										compatibility with the person they care for. We spend a lot
										of time getting to know our clients, so we can ensure the
										care they receive is tailored to their way of life.
									</p>
									<p className="primary">
										Some of our clients choose live-in care for a elderly
										relative who requires help with day-to-day tasks such as
										washing, making meals, medication, whilst others opt for 24
										hour care for a more intense level of care, such as dementia
										care.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section className="local-community-mobile">
						<div className="location-local-community">
							<h2>
								We help your loved ones remain a part of the local community
							</h2>
							<div className="local-community-cards">
								<CarouselComponent>
									<CarouselComponentSlide>
										<div className="community-card slide active">
                                            <GatsbyImage image={activityImage1} objectFit='cover' style={{height: '100%', width: '100%', borderRadius: '4px'}}/>
											<div className="community-card-content">
												<p className="community-card-activity"> {activity1.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity1.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="community-card slide">
                                            <GatsbyImage image={activityImage2} objectFit='cover' style={{height: '100%', width: '100%', borderRadius: '4px'}}/>
											<div className="community-card-content">
												<p className="community-card-activity"> {activity2.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity2.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="community-card slide">
                                            <GatsbyImage image={activityImage3} objectFit='cover' style={{height: '100%', width: '100%', borderRadius: '4px'}}/>
											<div className="community-card-content">
												<p className="community-card-activity"> {activity3.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity3.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="community-card slide">
                                            <GatsbyImage image={activityImage4} objectFit='cover' style={{height: '100%', width: '100%', borderRadius: '4px'}}/>
											<div className="community-card-content">
												<p className="community-card-activity"> {activity4.Activity_description} </p>
												<p className="community-card-location">
													{" "}
													{activity4.Location_name}{" "}
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

								</CarouselComponent>
							</div>
						</div>
					</section>
					<section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<h3 className="text-with-quotes">
										<span className="customer-words-group__quotes">“</span>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.<span className="customer-words-group__quotes">”</span>
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<StaticImage
									src="../../static/assets/marketing-site/images/dementia-quote-customers.png"
									objectFit='contain'
									placeholder="blurred"
								/>
								<div className="customer-names">
									<p className="secondary">
										Sarah M.
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie M.
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section>
					{/* CREATE NEW SECTION AND HIDE IT ON MACBOOK AND DESKTOP */}
					<section className="london-team">
						<div className="locations-london-team">
							<h1>Meet our local {airtableLocations.data.Name} team</h1>
							<div className="london-team">
								{/* ADD CAROUSEL */}
								<CarouselComponent>
									<CarouselComponentSlide>
										<div className="team-member">
										<StaticImage
											className="member-img"
											src="../../static/assets/marketing-site/images/1_london_care.png"
											alt="Gus"
											objectFit="contain"
											placeholder="blurred"
										/>

											<div className="member-role">
												<p>Sales Manager</p>
											</div>
											<div className="member-name">
												<h3>Tilda Mew</h3>
											</div>
											<div className="member-description">
												<p className="primary">
													We’re here to answer all your questions and help you
													navigate your options. Call us anytime on 020 3970 9900 or
													alternatively book a call here.
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="team-member">
										<StaticImage
											className="member-img"
											src="../../static/assets/marketing-site/images/2_london_care.png"
											alt="Oliver"
											objectFit="contain"
											placeholder="blurred"
										/>

											<div className="member-role">
												<p>COO</p>
											</div>
											<div className="member-name">
												<h3>Oliver Ross</h3>
											</div>
											<div className="member-description">
												<p className="primary">
													Oliver meets all of our clients in {airtableLocations.data.Name} in
													person and draws up a personalised care plan to ensure we
													tailor our service to their needs, likes and way of life.
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

									<CarouselComponentSlide>
										<div className="team-member">
										<StaticImage
											className="member-img"
											src="../../static/assets/marketing-site/images/3_london_care.png"
											alt="Maria"
											objectFit="contain"
											placeholder="blurred"
										/>

											<div className="member-role">
												<p>Field Care Supervisor</p>
											</div>
											<div className="member-name">
												<h3>Maria Perebzjak</h3>
											</div>
											<div className="member-description">
												<p className="primary">
													Maria works with our professional carers, district nurses,
													OTs and GPs to ensure the highest quality of care is
													provided.
												</p>
											</div>
										</div>
									</CarouselComponentSlide>

								</CarouselComponent>
							</div>
						</div>
					</section>

					<section>
						<div className="locations-livein-goto">
							<div className="goto-left">
								<h1>
									Why live-in care in {airtableLocations.data.Name} is now the go-to option
									instead of residential care
								</h1>
							</div>
							<div className="goto-right">
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-avoid-stress.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Avoid the potential stress of relocation</h3>
										<p className="primary">
											We can understand if you're starting your care journey you
											might have been searching around every care home in {" "}
											{airtableLocations.data.Name}. It’s a long and arduous process - we can
											sympathise, we’ve been there! We all want what is best for
											our family members and navigating can be hard.
										</p>
									</div>
								</div>
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-maintain-control.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Maintain control over your routine</h3>
										<p className="primary">
											Live-in care enables a fulfilling lifestyle that your
											loved one can control. They can keep doing the things they
											love, whether it’s a hobby, an activity in their own
											community or something as simple as having a friendly chat
											with an old neighbor. Maintaining this familiarity is so
											powerful.
										</p>
									</div>
								</div>
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-peace-of-mind.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Peace of mind for family and friends</h3>
										<p className="primary">
											A live-in care arrangement also benefits friends and
											family who have the peace of mind that a professional
											carer is always at hand to provide a level of care and
											assistance that is entirely commensurate with the needs of
											the individual.
										</p>
									</div>
								</div>
								<div className="goto-reason">
									<StaticImage
										src="../../static/assets/marketing-site/images/goto-personalize.png"
										alt=""
										objectFit='contain'
										placeholder="blurred"
									/>
									<div className="goto-content">
										<h3>Personalise to you</h3>
										<p className="primary">
											The beauty of our service is that it can be as flexible or
											structured as you like. We tailor it to your requests.
											Compared to a care home where things are very regimented
											they can eat the meal they want, when they want. Our
											professional carers can cook up a masterful sunday roast,
											a best of british steak or something highly nutritious.
											Our carefully matched carers get to know your loved one’s
											like they were a family member. Building relationship with
											client over time
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
                        <BrochureRequestPanel
							className='locations-page'
							deleteBg
							gtmContainerId={gtmContainerId}
                            quoteImage={(
                                <StaticImage
                                    src="../../static/assets/marketing-site/images/sandra-img.png"
                                    alt=""
                                    objectFit="contain"
                                    placeholder="blurred"
                            />)}
                            quoteText={`
                                Getting Mum out of a care home was made so much easier by
                                the Edyn team. They worked closely with the home to make
                                it as smooth as possible and now Mum is back in her home
                                in far better spirits. I couldn’t be happier
                            `}
                            clientDescription={(
                                <>
                                    <p>Sandra</p>
                                    <p>Daughter of a client</p>
                                </>
                            )}
                        />
					</section>
				</main>
    			<Footer />
			</div>
		</>
	);
}

export const pageQuery = graphql`
  	query($slug: String!) {
		site {
			siteMetadata {
				appConfig {
					gtmContainerId
				}
			}
		}
		airtableLocations(
			data: { slug: { eq: $slug } }
		) {
			data {
				slug
				Name
				Splash_image {
					url
				}
                Splash_image_sharp {
					childImageSharp {
                        gatsbyImageData(
                            width: 800
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
				Splash_Image_location_description
				Image_Gallery_1 {
					url
				}
				Image_gallery_2 {
					url
				}
				Image_Gallery_3 {
					url
				}
				activities {
					data {
						Activity_description
						image {
							url
							type
						}
                        image_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 400
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
						Location_name
					}
				}
			}
		}
	}
`