import React, {useState, useCallback, useEffect} from 'react'
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string'
import BackgroundImage from 'gatsby-background-image';
import QuotationMark from "../components/icons/LiveInCare/QuotationMark";
import {validate as validateEmail} from 'email-validator'
import Loading from './Animations/Loading/Loading'
import Sent from './Animations/Sent/Sent';

/* global _paq */

const submissionStatusStates = {
    NOT_SUBMITTED: 'NOT_SUBMITTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
}

const BrochureRequestPanel = ({quoteImage, quoteText, clientDescription, deleteBg, className='', gtmContainerId}) => {
    const [submittionStatus, setSumissinStatus] = useState(submissionStatusStates.NOT_SUBMITTED)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [startNeed, setStartNeed] = useState('asap')
    const [validEmail, setValidEmail] = useState(false)
    
    useEffect(() => {
        if (validateEmail(email)) return setValidEmail(true)
        return setValidEmail(false)
    }, [email, name, startNeed])

    useEffect(() => {
        if (!gtmContainerId) return
        window.dataLayer = window.dataLayer || [];

        function gtag(){window.dataLayer.push(arguments)};
        gtag('js', new Date());
        gtag('config',gtmContainerId);
    }, [gtmContainerId])

    const trackFormSubmissionEvent = useCallback(() => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'edyn_form_submission',
            form_name: 'brochure_request'
        });
    }, [])

    const submitBrochureRequest = useCallback(async (e) => {
        if (typeof _paq !== 'undefined') {
            _paq && _paq.push(['trackEvent', 'BrochureRequestPanel', 'Submit']);
        }
        setSumissinStatus(submissionStatusStates.IN_PROGRESS)
        e.preventDefault()
        const [firstName, ...lastNames] = name.split(' ')
        trackFormSubmissionEvent();
        let meta;
        try {
          meta = queryString.parse(document.location.search)
        } catch (e) {
          console.error(e)
        }
        await fetch('https://hooks.zapier.com/hooks/catch/6117983/bovj28r/', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {}, // zapier does not return the correct cors headers, when means we cannot set a 'Content-Type'
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                firstName,
                lastName: lastNames.join(' '),
                email,
                phone,
                startNeed,
                enquiryType: 'client',
                formName: 'brochure request',
                meta
            })
        })
        setSumissinStatus(submissionStatusStates.COMPLETED)
    }, [name, email, startNeed, phone])

    const formComplete = name && validEmail && startNeed
    const submitButtonDisabled = Boolean(!formComplete || submittionStatus !== submissionStatusStates.NOT_SUBMITTED)
    const submitButtonText = submittionStatus === submissionStatusStates.COMPLETED ? 'We have sent a brochure to your email' : 'Request a brochure';
    const submitButtonClassNameModifier = (submitButtonDisabled || submittionStatus === submissionStatusStates.IN_PROGRESS) 
        ? '--disabled' : submittionStatus === submissionStatusStates.SUCCESS
            ? '--success'
            : ''
    let Animation;
    switch (submittionStatus){
        case submissionStatusStates.NOT_SUBMITTED:
            Animation = ""
            break
        case submissionStatusStates.IN_PROGRESS:
            Animation = <Loading />
            break
        default:
            Animation = <Sent />
    }

    const {jaiSmithBg} = useStaticQuery(
        graphql`
            query{
                jaiSmithBg: file(relativePath: { eq: "jai-smith-bg.png" }) {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )
        

    return (
        <div className={`location-livein-guide ${className}`}>
            {deleteBg ? 
                <div className="livein-guide-left">
                    <div className="sandra-quote">
                        <div className="sandra-quote-1">
                            <div className="location-quotation-mark">“</div>
                            <p className="quote primary">
                                <div style={{ display: "flex" }}>
                                    <span className="location-quotation-mark-text-pre">“</span>
                                    <div>
                                        {quoteText}
                                        <span className="location-quotation-mark-text">”</span>
                                    </div>
                                </div>
                            </p>
                        </div>
                        <div className="sandra">
                            {quoteImage}
                            <div className="description">
                                {clientDescription}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <BackgroundImage className="livein-guide-left" fluid={jaiSmithBg.childImageSharp.fluid}>
                    <div className="sandra-quote">
                        <div className="sandra-quote-1">
                            <QuotationMark />
                            <p className="quote primary">
                                {quoteText}”
                            </p>
                        </div>
                        <div className="sandra">
                            {quoteImage}
                            <div className="description">
                                {clientDescription}
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            }
            <div className="livein-guide-right">
                <div className="form-container">
                    <h1>Live-in care guide</h1>

                    <div className="form-content">
                        <StaticImage
                            src="../../static/assets/marketing-site/images/live-in-form.png"
                            objectFit="contain"
                            placeholder="blurred"
                        />
                        <p className="primary">
                            Find out more about how live-in care might be suitable for
                            your and your family with our helpful guide.
                        </p>
                    </div>
                    <form onSubmit={submitBrochureRequest}>
                        <div className="input-group">
                            <label for="name">Full name *</label>
                            <input className="default" type="text" name='name' value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="input-group">
                            <label for="email">Email *</label>
                            <input className="default" type="email" name='email' value={email} onChange={e => {
                                    setEmail(e.target.value)
                                }
                            }/>
                        </div>
                        <div className="input-group">
                            <label for="phone">Phone number</label>
                            <input className="default" type="text" name='phone' value={phone} onChange={e => {
                                    setPhone(e.target.value)
                                }
                            }/>
                        </div>
                        <div className="input-group">
                            <label for="startNeed">When do you need care to start?</label>
                            <select className="default" name="startNeed" value={startNeed} onChange={e => setStartNeed(e.target.value)}>
                                <option value='asap'>ASAP</option>
                                <option value='1-2weeks'>1-2 Weeks</option>
                                <option value='2-4weeks'>2-4 Weeks</option>
                                <option value='4weeks+'>4 Weeks +</option>
                                <option value='unsure'>Unsure</option>
                            </select>
                        </div>
                        <div className="input-group">
                            <button
                                type="submit"
                                className={`brochure-request-form-submit brochure-request-form-submit${submitButtonClassNameModifier}`}
                                disabled={submitButtonDisabled}
                            >
                                <div className="submit-text">{submitButtonText}</div>
                                <div className="icon">
                                    {Animation}
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
       
    )
}

export default BrochureRequestPanel;